import axios from 'axios'
import Store from './store';
import { toast } from 'react-toastify';

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.data.status === false) {
        toast.error(response.data.message);
    } else if (response.data.status === true && response.data.message) {
        toast.success(response.data.message);
    }
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response?.data?.message) {
        toast.error(error.response.data.message);
    } else {
        toast.error(error.message);
    }

    // Do something with response error
    return Promise.reject(error);
});

class Lancely {
    constructor(options) {
        this.storage = new Store();

        this.HTTPRegexp = /^http:\/\//;
        let config = {
            apiURL: "http://localhost:5000/",
            audience: "lancely"
        };
        config = Object.assign({}, config, options);
        if (config.apiURL.match(this.HTTPRegexp)) {
            console.log("%cWARNING", "font-size: 25px; color: orange;");
            console.log("%cDO NOT USE HTTP IN PRODUCTION!", "font-weight: bold;");
        }
        this.config = config;
    }


    async request(endpoint, parameters, method = "GET", auth = false, options = {}) {

        if (this.users.isExpired()) {
            await this.users.refresh()
                .then(resp => {
                    let data = this.storage.get('auth')
                    data = { ...data, ...resp }
                    this.storage.set('auth', data);
                })
        }

        let request = {
            method,
            url: `${this.config.apiURL}${endpoint}`,

        };
        if (method === "GET") {
            request = {
                ...request,
                params: parameters
            }
        } else {
            request = {
                ...request,
                data: parameters
            }
        }

        if (auth) {
            request = {
                ...request, headers: {
                    Authorization: 'Bearer ' + this.storage.get('auth')?.tokens?.access_token || "" //the token is a variable which holds the token
                }
            }
        }
        const response = axios({ ...request, ...options });
        return response;
    }

    companies = {
        checkSlug: async (slug) => {
            return this.request(`slugTaken`, { slug }, 'GET', true)
                .then(resp => resp.data);
        },
        getMarketplace: async (page = 1, limit = 10) => {
            return this.request(`marketplace`, { page, limit }, 'GET', true)
                .then(resp => resp.data);
        },
        get: async (id) => {
            return this.request(`companies/${id}`, {}, 'GET', true)
                .then(resp => resp.data);
        },
        getAll: async () => {
            return this.request(`companies`, {}, 'GET', true)
                .then(resp => resp.data);
        },
        update: async (id, data) => {
            return this.request(`companies/${id}`, data, 'PUT', true)
                .then(resp => resp.data);
        }
    }

    projects = {
        get: async (id) => {
            return this.request(`project/${id}`, {}, 'GET', true)
                .then(resp => resp.data);
        },
        getAll: async (page = 0) => {
            return this.request('projects', { page }, 'GET', true)
                .then(resp => resp.data);
        },
        create: async (data) => {
            return this.request('projects', data, 'POST', true)
                .then(resp => resp.data);
        },
        update: async (id, data) => {
            return this.request(`project/${id}`, data, 'PUT', true)
                .then(resp => resp.data);
        },
        delete: async (id) => {
            return this.request(`project/${id}`, {}, 'DELETE', true)
                .then(resp => resp.data);
        },

        threads: {
            get: async (id) => {

            },
            getAll: async (projectId) => {
                return this.request(`project/${projectId}/threads`, {}, 'GET', true)
                    .then(resp => resp.data);
            },
            create: async (projectId, content) => {
                return this.request(`project/${projectId}/thread`, { content }, 'POST', true)
                    .then(resp => resp.data);
            }
        },
        files: {
            getAll: async (projectId) => {
                return this.request(`project/${projectId}/files`, {}, 'GET', true)
                    .then(resp => resp.data);
            },
            getAllWithFolder: async (projectId, folderId) => {
                return this.request(`project/${projectId}/files/${folderId}`, {}, 'GET', true)
                    .then(resp => resp.data);
            },
            create: async (projectId, data, config) => {
                return this.request(`project/${projectId}/files`, data, 'POST', true, config)
                    .then(resp => resp.data);
            },
            delete: async (projectId, fileId) => {
                return this.request(`project/${projectId}/files/${fileId}`, {}, 'DELETE', true)
                    .then(resp => resp.data);
            },
            like: async (projectId, fileId) => {
                return this.request(`project/${projectId}/files/${fileId}/like`, {}, 'POST', true)
                    .then(resp => resp.data);
            },
            unlike: async (projectId, fileId) => {
                return this.request(`project/${projectId}/files/${fileId}/unlike`, {}, 'POST', true)
                    .then(resp => resp.data);
            },
            highlight: async (companyId, fileId) => {
                return this.request(`companies/${companyId}/highlight`, { folderId: fileId }, 'POST', true)
                    .then(resp => resp.data);
            },
            unhighlight: async (companyId, fileId) => {
                return this.request(`companies/${companyId}/unhighlight`, { folderId: fileId }, 'POST', true)
                    .then(resp => resp.data);
            }
        },
        invoices: {
            getAll: async (projectId) => {
                return this.request(`project/${projectId}/invoices`, {}, 'GET', true)
                    .then(resp => resp.data);
            },
            get: async (projectId, invoiceId) => {
                return this.request(`project/${projectId}/invoices/${invoiceId}`, {}, 'GET', true)
                    .then(resp => resp.data);
            },
            create: async (projectId, data) => {
                return this.request(`project/${projectId}/invoices`, data, 'POST', true)
                    .then(resp => resp.data);
            },
            delete: async (projectId, invoiceId) => {
                return this.request(`project/${projectId}/invoices/${invoiceId}`, {}, 'DELETE', true)
                    .then(resp => resp.data);
            },
            pay: async (projectId, invoiceId) => {
                return this.request(`project/${projectId}/invoices/${invoiceId}/pay`, {}, 'GET', true)
                    .then(resp => resp.data);
            },
            update: async (projectId, invoiceId, data) => {
                return this.request(`project/${projectId}/invoices/${invoiceId}`, data, 'PUT', true)
                    .then(resp => resp.data);
            }
        },
        members: {
            getAll: async (id) => {
                return this.request(`project/${id}/members`, {}, 'GET', true)
                    .then(resp => resp.data);
            },
            add: async (id, data) => {
                return this.request(`project/${id}/members`, data, 'POST', true)
                    .then(resp => resp.data);
            },
            update: async (id, memberId, data) => {
                return this.request(`project/${id}/members/${memberId}`, data, 'PUT', true)
                    .then(resp => resp.data);
            },
            delete: async (id, memberId) => {
                return this.request(`project/${id}/members/${memberId}`, {}, 'DELETE', true)
                    .then(resp => resp.data);
            }
        }
    }

    users = {
        login: async (email, password) => {
            return this.request('login', { email: email.trim(), password: password.trim() }, 'POST')
                .then(resp => {
                    return resp.data;
                })
        },
        signup: async (firstName, lastName, email, password, type = "client") => {
            return this.request(`signup?type=${type}`, { firstName, lastName, email, password }, 'POST');
        },
        logout: () => {
            // Clear all tokens and data
            this.storage.clearAll();
        },
        isExpired: () => {
            const token = this.storage.get('auth').tokens?.access_token || null;
            if (token !== null) {
                const data = JSON.parse(atob(token.split('.')[1]));
                if (data.exp < Math.floor(Date.now() / 1000)) {
                    return true
                } else {
                    return false;
                }
            }
            return false;
        },
        refresh: async () => {
            let data = this.storage.get('auth');
            let req = {
                method: 'GET',
                url: `${this.config.apiURL}refresh`,
                headers: {
                    Authorization: 'Bearer ' + data?.tokens?.refresh_token || "" //the token is a variable which holds the token
                }
            }

            return axios(req)
                .then(resp => resp.data)
        },
        checkUsername: async (username) => {
            return this.request(`checkUsername`, { username }, 'GET', true)
                .then(resp => resp.data);
        },
        follow: async (id) => {
            return this.request(`users/${id}/follow`, {}, 'POST', true)
                .then(resp => resp.data);
        },
        unfollow: async (id) => {
            return this.request(`users/${id}/unfollow`, {}, 'POST', true)
                .then(resp => resp.data);
        },
        update: async (data) => {
            return this.request(`user`, data, 'PUT', true)
                .then(resp => resp.data);
        }
    }

    events = {
        getAllByProject: async (projectId, data = {}) => {
            return this.request(`events/${projectId}`, data, 'GET', true)
                .then(resp => resp.data);
        },
        getAll: async (data = {}) => {
            return this.request(`events`, data, 'GET', true)
                .then(resp => resp.data);
        },
        create: async (data) => {
            return this.request(`events`, data, 'POST', true)
                .then(resp => resp.data);
        },
        // book event with user
        book: async (userId, data) => {
            return this.request(`events/${userId}`, data, 'POST', true)
                .then(resp => resp.data);
        }
    }
}

const lancely = new Lancely({
    apiURL: process.env.REACT_APP_API_URL
});

export default lancely;