import React, { useEffect, useState, useContext } from 'react'

// import { UserContext } from '../../context/UserContext'

import lancely from '../../utils/lancely'

import { format } from 'date-fns';
import { Modal } from 'react-bootstrap';

import Kalend, { CalendarView } from 'kalend' // import component
import 'kalend/dist/styles/index.css'; // import styles
import './Events.css';

function Events() {

    // TODO: Make sure as the user scrolls through the calendar
    // the appropriate events are displayed in that date range.

    // const { dispatch, state } = useContext(UserContext);
    const [events, setEvents] = useState([]);
    const projectColorMappings = [
        "#0d6efd",
        "#6610f2",
        "#6f42c1",
        "#d63384",
        "#dc3545",
        "#fd7e14",
        "#ffc107",
        "#198754",
        "#6c757d",
        "#17a2b8",
    ];
    const [createModal, setCreateModal] = useState(false);
    const [projectList, setProjectList] = useState([]);
    const [newEvent, setNewEvent] = useState({
        name: '',
        project: '',
        startDate: new Date(),
        endDate: new Date(),
    });

    const getEvents = () => {
        lancely.events.getAll()
            .then(resp => {
                // change the event key names to match the kalend component
                const modifiedEvents = resp.events.map((event, index) => {
                    return {
                        ...event,
                        summary: event.name,
                        startAt: event.startDate,
                        endAt: event.endDate,
                        color: projectColorMappings[index % projectColorMappings.length],
                    }

                })
                setEvents(modifiedEvents);
            })
            .catch(err => {
                console.log(err);
            });
    }

    const getProjects = () => {
        lancely.projects.getAll()
            .then(resp => {
                setProjectList(resp.projects);
            })
            .catch(err => {
                console.log(err);
            });
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        lancely.events.create(newEvent)
            .then(resp => {
                getEvents();
                setNewEvent({
                    name: '',
                    project: '',
                    startDate: new Date(),
                    endDate: new Date(),
                });
                setCreateModal(false);
            })
            .catch(err => {
                console.log(err);
            });
    }

    useEffect(() => {
        getEvents();
        getProjects();
    }, []);

    return (
        <>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="container mt-3">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h4 className="card-title">Events</h4>
                                        </div>
                                        <div className="col-md-6 text-end">
                                            <button className="btn btn-primary" onClick={() => {
                                                setCreateModal(true);
                                            }}>Create Event</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body h-100">
                                {/* <div className="table-responsive">
                                <table className="table">
                                    <thead className="">
                                        <th>
                                            Event Name
                                        </th>
                                        <th>
                                            Event Start
                                        </th>
                                        <th>
                                            Event End
                                        </th>

                                    </thead>
                                    <tbody>
                                        {events.map(event => (
                                            <tr key={event._id}>
                                                <td>
                                                    {event.name}
                                                </td>

                                                <td>
                                                    {format(new Date(event.startDate), 'MM/dd/yyyy')}
                                                </td>
                                                <td>
                                                    {format(new Date(event.endDate), 'MM/dd/yyyy')}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div> */}


                                <Kalend
                                    onEventClick={() => { }}
                                    onNewEventClick={() => { }}
                                    events={events}
                                    initialDate={new Date().toISOString()}
                                    hourHeight={60}
                                    initialView={CalendarView.WEEK}
                                    draggingDisabledConditions={[]}
                                    // disabledViews={[CalendarView.DAY]}
                                    // onSelectView={onSelectView}
                                    // selectedView={selectedView}
                                    // onPageChange={onPageChange}
                                    timeFormat={'24'}
                                    weekDayStart={'Sunday'}
                                    calendarIDsHidden={['work']}
                                    language={'en'}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={createModal} onHide={() => { setCreateModal(false); }} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Create Event</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className={`card shadow mb-4`}>
                                    <div className={`card-body`}>
                                        <form onSubmit={handleSubmit}>
                                            {/* show projects as select option */}
                                            <div className="form-group">
                                                <label htmlFor="name">Event Name</label>
                                                <input type="text" className="form-control" id="name" name="name" value={newEvent.name} onChange={(e) => {
                                                    setNewEvent({
                                                        ...newEvent,
                                                        name: e.target.value
                                                    });
                                                }} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="project">Project</label>
                                                <select className="form-control" id="project" name="project" value={newEvent.project} onChange={(e) => {
                                                    setNewEvent({
                                                        ...newEvent,
                                                        project: e.target.value
                                                    });
                                                }}>
                                                    <option value="">---</option>
                                                    {projectList.map(project => (
                                                        <option key={project._id} value={project._id}>{project.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="startDate">Start Date</label>
                                                <input type="datetime-local" className="form-control" id="startDate" name="startDate" value={newEvent.startDate} onChange={(e) => {
                                                    setNewEvent({
                                                        ...newEvent,
                                                        startDate: e.target.value
                                                    });
                                                }} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="endDate">End Date</label>
                                                <input type="datetime-local" className="form-control" id="endDate" name="endDate" value={newEvent.endDate} onChange={(e) => {
                                                    setNewEvent({
                                                        ...newEvent,
                                                        endDate: e.target.value
                                                    });
                                                }} />
                                            </div>
                                            <div className={`form-group mt-5 text-center`}>
                                                <button type="submit" className="btn btn-primary">Create Event</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default Events