import React, { useEffect, useState, useContext } from "react";

import LogRocket from 'logrocket';

// Routing
import {
	BrowserRouter as Router,
	Routes,
	Route
} from "react-router-dom";

// API / Contexts
import lancely from './utils/lancely';
import { SET_TOKENS, SET_USER_DATA, CLEAR, UserContext } from "./context/UserContext";

// Styles and toast notifications
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';

import { Container, Row, Col, Modal, Form } from "react-bootstrap";

// Sidebar and Global Search components
import Sidebar from "./components/Sidebar/Sidebar";
import GlobalSearch from "./components/Sidebar/GlobalSearch";
import NotFound from "./components/NotFound";

// Socket.io
import io from 'socket.io-client';

// Pages and Components
import Home from "./components/Home/Home";
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import Reset from "./components/Login/Reset";
import Projects from "./components/Projects/Projects";
import Chat from "./components/Chat/Chat";
import Settings from './components/Settings/Settings';
import SetupCompany from "./components/SetupCompany/SetupCompany";
import Marketplace from "./components/Marketplace/Marketplace";
import Reports from "./components/Reports/Reports";
import Profile from './components/Profile/Profile';
import ProjectView from "./components/Projects/ProjectView/ProjectView";
import Edit from "./components/Profile/Edit";
import Events from "./components/Events/Events";


export default function App() {

	const [socket, setSocket] = useState(null);
	const [setupCompany, setSetupCompany] = useState(false);

	const { dispatch: userDispatch, state } = useContext(UserContext);

	LogRocket.init('bun5ed/lancely-co');

	// Refresh function.
	const refresh = () => {
		lancely.users.refresh()
			.then(resp => {
				userDispatch({
					type: SET_TOKENS,
					payload: { ...resp.tokens }
				})
				userDispatch({
					type: SET_USER_DATA,
					payload: { ...resp.user }
				})
			})
	}

	// Setting up a socket connection
	// if the user / when the user logs in
	useEffect(() => {
		// if (state && state.tokens !== null && state.tokens.access_token !== null) {
		// 	// refresh();
		// 	// State is loaded!
		// 	let sock = io(process.env.REACT_APP_API_URL, {
		// 		query: {
		// 			token: state.tokens.access_token
		// 		}
		// 	})
		// 	setSocket(sock)
		// 	sock.on('notification', (data) => {
		// 		console.log(`New Notification:`, data);
		// 		toast.success(data);
		// 	})
		// 	sock.on("connect_error", (err) => {
		// 		toast.error(err.message);
		// 		// if (err.message === "invalid username") {
		// 		// this.usernameAlreadySelected = false;
		// 		// }
		// 	});
		// }

		if (state && state.user !== null) {
			// If they have a freelancer role
			// but don't have a company ask them
			// to make one before they can continue
			if (state.user?.roles.includes("freelancer") && state.user?.companies.length === 0) {
				// alert("You need a company buddy")
				setSetupCompany(true)
			}

			LogRocket.identify(state.user?._id, {
				name: `${state.user?.firstName} ${state.user?.lastName}`,
				email: state.user?.email,
				// Add your own custom user variables here, ie:
				userType: state.user?.roles,
			  });

		}

	}, [state])

	// Refresh the token every 5 minutes
	useEffect(() => {
		const interval = setInterval(() => {
			// Just refresh the token every 5 mins
			if (state && state.tokens !== null) {
				refresh();
			}
		}, 5 * 60 * 1000);
		// if (state && state.tokens !== null) {
		refresh();
		// }
		return () => clearInterval(interval);
	}, []);


	// Our pages and their paths
	const routes = [
		{ component: <Home />, path: '/' },
		{ component: <Projects />, path: '/projects' },
		{ component: <ProjectView />, path: '/project/:projectId' },
		{ component: <Chat />, path: '/chat' },
		{ component: <Settings />, path: '/settings' },
		{ component: <Marketplace />, path: '/marketplace' },
		{ component: <Reports />, path: '/reports' },
		{ component: <Profile />, path: '/company/:username' },
		{ component: <Edit />, path: '/edit' },
		{ component: <Events />, path: '/events' },
	];

	return (
		<>
			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<Container fluid className={`g-0`} style={{ backgroundColor: "#F5F6F8" }}>
				<Row className={`g-0`}>
					<Router>
						<Col
							md={'auto'}
							style={{
								maxWidth: '300px',
								width: '30vw',
								// zIndex: 1
							}}
							id="sidebar-wrapper"
							className={state.user !== null ? '' : 'd-none'}
						>
							<Sidebar />
						</Col>
						<Col id="page-content-wrapper" style={{ maxHeight: "100vh", overflowY: "auto" }}>
							{state.user !== null && <GlobalSearch socket={socket} company={state.user?.companies[0] || null} />}
							<Routes>
								{state.user !== null &&
									routes.map((route, index) => {
										return <Route key={index} path={route.path} element={route.component} />
									})
								}
								<Route path={state.user === null ? "/" : "/login"} element={<Login />} />
								<Route path="/register" element={<Register />} />
								<Route path="/reset" element={<Reset />} />
								<Route path="*" element={<NotFound />}></Route>
							</Routes>
						</Col>
					</Router>
				</Row>
			</Container>
			{
				setupCompany && <SetupCompany completedSetup={() => {
					setSetupCompany(false);
					refresh();
				}} />
			}
		</>
	);
}
