import React, { useState, useEffect, useContext } from 'react';

import lancely from '../../utils/lancely';
import { UserContext } from '../../context/UserContext';

import { Container, Row, Col, Card, Table } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';

import './Marketplace.css';

function Marketplace() {

    const { state } = useContext(UserContext);
    const navigate = useNavigate();

    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        lancely.companies.getMarketplace()
            .then(res => {
                console.log(res);
                setCompanies(res.companies);
            })
            .catch(err => {
                console.log(err);
            })
    }, []);

    if (state && !state.user) {
        return <div>Loading...</div>
    }

    return (<>
        <div className="container mt-5">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className='card-header mt-5'>
                            <p className={`display-5 fw-light`}>Marketplace</p>
                            
                        </div>
                        <div className="card-body">
                            <p>Here are some of our top sellers and companies! Check them out and view their portfolios.</p>
                            {/* create cards from companies array */}
                            <Container className={`mt-5`}>
                                <Row>
                                    {companies.map((company, i) => {
                                        return (
                                            <Col key={i} md={4} style={{ cursor: 'pointer' }} onClick={() => {
                                                navigate(`/company/${company.slug}`)
                                            }}>
                                                <Card>

                                                    <div className={`cardImageItem`}>
                                                        <span className={`cardImageNotify`}>{company.projects.length} projects</span>
                                                        <Card.Img variant="top" src={ ([undefined, null, ""].includes(company.logo) ? "/assets/icons/no-image.png" : company.logo)} />
                                                    </div>

                                                    <Card.Body>
                                                        <Card.Title>{company.name}</Card.Title>
                                                        <Card.Text>
                                                            <p className="lead">
                                                                {company.bio}
                                                            </p>
                                                            {/* <p>
                                                                {company.members.length + 1} member{company.members.length + 1 > 1 ? "s" : ""}
                                                            </p> */}
                                                            <button className='btn btn-primary btn-sm'
                                                                onClick={() => {
                                                                    navigate(`/company/${company.slug}`)
                                                                }}
                                                            >
                                                                View Profile
                                                            </button>
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}

export default Marketplace;
