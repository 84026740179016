import React, { useState, useEffect } from 'react'

import lancely from '../../../utils/lancely';

import { ProgressBar, Modal } from 'react-bootstrap';
import './ProjectFiles.css';

function ProjectFiles({ project, user, updateProject }) {

  const [folder, setFolder] = useState(null);
  const [files, setFiles] = useState(null);
  const [storageUsage, setStorageUsage] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [filtered, setFiltered] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);

  const handleChange = (e) => {
    setFiles(e.target.files);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append(`images`, files[i])
    }
    lancely.projects.files.create(project._id, formData, {
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setUploadProgress(<ProgressBar now={percentCompleted} label={`${percentCompleted}%`} />);
      }
    })
      .then(resp => {
        getAllFiles();
        setUploadProgress(null);
      })


  }

  const getAllFiles = () => {
    lancely.projects.files.getAll(project._id)
      .then(resp => {
        setFolder(resp.folder);

        // calculate total size of files
        let totalSize = 0;
        resp.folder.children.forEach(file => {
          totalSize += file.size;
        });
        // convert bytes to MB
        const totalSizeMB = totalSize / 1000000;
        setStorageUsage(totalSizeMB.toFixed(3));
      })
      .catch(err => {
        console.log(err);
      });
  }

  const likeImage = (fileId) => {
    lancely.projects.files.like(project._id, fileId)
      .then(resp => {
        getAllFiles();
      })
      .catch(err => {
        console.log(err);
      });
  }

  const unlikeImage = (fileId) => {
    lancely.projects.files.unlike(project._id, fileId)
      .then(resp => {
        getAllFiles();
      })
      .catch(err => {
        console.log(err);
      });
  }

  const deleteFile = (fileId) => {
    lancely.projects.files.delete(project._id, fileId)
      .then(resp => {
        getAllFiles();
      })
      .catch(err => {
        console.log(err);
      });
  }

  const isHighlighted = (fileId) => {
    if (project.owner && project.owner.highlights) {
      return project.owner.highlights.includes(fileId);
    }
    return false;
  }

  const highlight = (fileId) => {
    lancely.projects.files.highlight(project.owner._id, fileId)
      .then(resp => {
        updateProject(false);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const unhighlight = (fileId) => {
    lancely.projects.files.unhighlight(project.owner._id, fileId)
      .then(resp => {
        updateProject(false);
      })
      .catch(err => {
        console.log(err);
      });
  }

  useEffect(() => {
    getAllFiles();
  }, [])

  return (
    <>
      <div className={`container`}>
        <div className={`row`}>
          {/* Show storage usage */}
          <div className="col-md-3 offset-md-9 mb-5">
            <div className={`card`}>
              <div className={`text-end card-body`}>
                <p className="lead" style={{ marginBottom: 0 }}>{storageUsage} MB Used</p>
              </div>
            </div>
          </div>
          {
            folder &&
            <>
              <div className={`col-md-12 text-center d-flex justify-content-evenly`}>
                {folder.children.length > 0 &&
                  <button
                    className={`btn btn-secondary`}
                    onClick={() => {
                      setFiltered(!filtered);
                    }}
                  >
                    Filter by Liked
                  </button>
                }
                <button
                  className={`btn btn-primary`}
                  onClick={() => {
                    setShowUploadModal(true);
                  }}
                >
                  Upload Files
                </button>
              </div>
            </>
          }

          <div className="spacer"></div>

          {
            folder && folder.children.length <= 0 &&
            <p className={`lead text-center mt-5`}>
              No files yet. You should upload something to get started!
            </p>
          }

          {
            filtered && folder && !folder.children.find(file => file.likedBy.length > 0) &&
            <div className={`col-md-12 text-center`}>
              <p className={`lead text-center mt-5`}>
                No files yet liked yet, come back later to see what others have liked!
              </p>
            </div>
          }

          {
            folder && folder.children.length > 0 && folder.children.filter(file => {
              if (filtered) {
                return file.likedBy.length > 0;
              } else {
                return true;
              }
            }).map((file, index) => {
              return (
                <div key={index} className={`col-lg-4`}>
                  <div className={`card shadow mb-4`}>
                    <div className={`card-body`}>
                      <h4 className={`card-title`}>{file.name}</h4>
                      {file.isFile && <img className={`img-thumbnail`} src={`https://ewr1.vultrobjects.com/lancely/${file.key}`} alt="file" />}
                      <div className={`row`} style={{ textAlign: 'center' }}>
                        <div className={`col-md-12 d-flex justify-content-evenly`}>
                          <div className={`m-1`}>
                            <input onChange={() => { file.likedBy && file.likedBy.includes(user._id) ? unlikeImage(file._id) : likeImage(file._id) }} type='checkbox' id={`toggle-${index}`} checked={file.likedBy && file.likedBy.includes(user._id)} />
                            <label for={`toggle-${index}`}>❤</label>
                          </div>
                          <div className={`m-1`}>
                            <button className={`btn btn-link`} onClick={() => {
                              deleteFile(file._id);
                            }}>🗑️</button>
                          </div>
                          <div className={`m-1`}>
                            <input onChange={() => {
                              !isHighlighted(file._id) ? highlight(file._id) : unhighlight(file._id);
                            }} type='checkbox' id={`toggle-star-${index}`} checked={isHighlighted(file._id)} />
                            <label for={`toggle-star-${index}`}>&#9733;</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
        <Modal show={showUploadModal} onHide={() => { setShowUploadModal(false); }} size="lg" centered>
          <Modal.Header closeButton>
            <Modal.Title>Upload Files</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  {uploadProgress}
                  <div className={`card shadow mb-4`}>
                    <div className={`card-body`}>
                      <p className={`lead`}>Select files for upload (max 10):</p>
                      <form onSubmit={handleSubmit}>
                        <div className={`form-group`}>
                          <input accept="image/*" type="file" id="images" multiple name="images" onChange={handleChange} className={`form-control`} />
                        </div>
                        <div className={`form-group mt-5 text-center`}>
                          <button type="submit" className="btn btn-primary">Upload File</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

      </div>



    </>
  )
}

export default ProjectFiles