import React from 'react'

import UserItem from './UserItem'

function UserView() {


    const users = [{
        firstName: "Lindsey",
        lastName: "Stroud",
        photo: "",
        message: "Lorem Ipsum"
    },
    {
        firstName: "Nicci",
        lastName: "Troiani",
        photo: "",
        message: "Lorem Ipsum"
    },
    {
        firstName: "John",
        lastName: "Smith",
        photo: "",
        message: "Lorem Ipsum"
    },
    {
        firstName: "Rebecca",
        lastName: "Moore",
        photo: "",
        message: "Lorem Ipsum"
    }
    ]


    return (
        <div
            className={`container bg-white`}
            style={{
                minHeight: '90vh',
                paddingTop: '3vh',
                borderRadius: '25px'
            }}>
            <div className={`row`}>
                <div className={`col-12`}>
                    <hr style={{ color: '#757779' }} />
                    {users && users.length > 0 && (
                        <>
                            <p className={`lead text-center`}>Users</p>
                            <hr style={{ color: '#757779' }} />
                        </>
                    )}
                    {users && users.map((user, index) => {
                        return (<div key={index} >
                            <UserItem user={user} />
                            {index === users.length ? '' : <hr style={{ color: '#B4B3BA' }} />}
                        </div>)
                    })}
                </div>
            </div>
        </div>
    )
}

export default UserView