import React, { useEffect, useState, useContext } from 'react'
import { toast } from 'react-toastify';

import { UserContext, SET_USER_DATA } from '../../context/UserContext'

import lancely from '../../utils/lancely'

function Edit() {

    const { dispatch, state } = useContext(UserContext);
    const [company, setCompany] = useState([]);

    const [userFormData, setUserFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        oldPassword: '',
        password: '',
        passwordConfirm: ''
    });

    const [companyFormData, setCompanyFormData] = useState({
        name: '',
        bio: '',
        logo: '',
    });

    const getCompanies = () => {
        lancely.companies.getAll()
            .then(resp => {
                setCompany(resp.companies[0]);
                setCompanyFormData({
                    name: resp.companies[0].name,
                    bio: resp.companies[0].bio,
                    logo: resp.companies[0].logo
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    const handleUserSubmit = (e) => {
        e.preventDefault();

        if (userFormData.password !== userFormData.passwordConfirm) {
            toast.error("Passwords do not match");
            return;
        }

        lancely.users.update(userFormData)
            .then(resp => {
                if (resp.status) {
                    toast.success("User updated!");
                    dispatch({
                        type: SET_USER_DATA,
                        payload: { ...resp.user }
                    })
                }
            })
            .catch(err => {
                toast.error(err.message);
            });

    }

    const handleCompanySubmit = (e) => {
        e.preventDefault();

        if (companyFormData.name === '' || companyFormData.bio === '') {
            toast.error('Please at least fill out company name and bio fields');
            return;
        }

        lancely.companies.update(company._id, companyFormData)
            .then(resp => {
                console.log(resp);
            })
            .catch(err => {
                console.log(err);
            });
    }

    useEffect(() => {
        getCompanies();

        if (state.user) {
            setUserFormData({
                firstName: state.user.firstName,
                lastName: state.user.lastName,
                email: state.user.email,
            });
        }

    }, [])


    return (
        <>
            {/* Edit user page */}
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Edit Profile</h5>
                                <form onSubmit={handleUserSubmit}>
                                    {/* user firstName */}
                                    <div className="form-group">
                                        <label htmlFor="firstName">First Name</label>
                                        <input type="text" className="form-control" id="firstName" placeholder="First Name" value={userFormData.firstName} onChange={(e) => setUserFormData({ ...userFormData, firstName: e.target.value })} />
                                    </div>
                                    {/* user lastName */}
                                    <div className="form-group">
                                        <label htmlFor="lastName">Last Name</label>
                                        <input type="text" className="form-control" id="lastName" placeholder="Last Name" value={userFormData.lastName} onChange={(e) => setUserFormData({ ...userFormData, lastName: e.target.value })} />
                                    </div>
                                    {/* user email */}
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Email address</label>
                                        <input
                                            disabled
                                            type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email"
                                            value={userFormData.email}
                                            onChange={(e) => setUserFormData({ ...userFormData, email: e.target.value })}
                                        />
                                        <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1">Current Password</label>
                                        <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Current Password"
                                            value={userFormData.oldPassword}
                                            onChange={(e) => setUserFormData({ ...userFormData, oldPassword: e.target.value })}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1">Password</label>
                                        <input type="password" className="form-control" id="exampleInputPassword1" placeholder="New Password"
                                            value={userFormData.password}
                                            onChange={(e) => setUserFormData({ ...userFormData, password: e.target.value })}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1">Confirm Password</label>
                                        <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Repeat New Password"
                                            value={userFormData.passwordConfirm}
                                            onChange={(e) => setUserFormData({ ...userFormData, passwordConfirm: e.target.value })}
                                        />
                                    </div>
                                    <div className="form-group mt-2">
                                        <button type="submit" className="btn btn-primary">Update User</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Edit company only if freelancer role */}
            {
                state.user && state.user?.roles?.includes('freelancer') && (
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Edit Company</h5>
                                        {/* create form with the fields: name, bio, logo, members */}
                                        <form onSubmit={handleCompanySubmit}>
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Name</label>
                                                <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter name"
                                                    value={companyFormData.name}
                                                    onChange={(e) => setCompanyFormData({ ...companyFormData, name: e.target.value })}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Bio</label>
                                                <textarea type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter bio"
                                                    value={companyFormData.bio}
                                                    onChange={(e) => setCompanyFormData({ ...companyFormData, bio: e.target.value })}
                                                ></textarea>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Logo URL</label>
                                                <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter logo"
                                                    value={companyFormData.logo}
                                                    onChange={(e) => setCompanyFormData({ ...companyFormData, logo: e.target.value })}
                                                />
                                            </div>
                                            {/* submit */}
                                            <div className="form-group mt-2">
                                                <button type="submit" className="btn btn-primary">Update Company</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            


        </>
    )
}

export default Edit