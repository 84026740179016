import React, { useEffect, useState } from 'react'

import { Modal, Form, Button } from 'react-bootstrap';

import useDebounce from '../../utils/useDebounce';

import lancely from '../../utils/lancely';

import './SetupCompany.css'

function SetupCompany({ completedSetup }) {

    const [name, setName] = useState("");

    const [slug, setSlug] = useState("");
    const [slugMessage, setSlugMessage] = useState({ show: false, status: false, message: "" });
    const debouncedSlug = useDebounce(slug, 1000);

    const [step, setStep] = useState(1);
    const [accountLink, setAccountLink] = useState("");

    useEffect(() => {
        if (name.length > 0) {
            setSlug(name.split(" ").join("").toLowerCase().replace(/[^\w\s]/gi, ''))
        }
    }, [name])

    useEffect(() => {
        console.log(debouncedSlug);
        lancely.request(`slugTaken`, { slug }, 'GET', true)
            .then(resp => resp.data)
            .then(resp => {
                setSlugMessage({
                    show: true,
                    ...resp
                });
            })

    }, [debouncedSlug])


    const handleSubmit = (e) => {
        e.preventDefault();
        lancely.request(`companies`, { name, slug }, 'POST', true)
            .then(resp => resp.data)
            .then(resp => {
                if (resp.status) {
                    setStep(2);
                    setAccountLink(resp.accountLink)
                }
            })
    }

    return (
        <Modal
            show={true}
            onHide={() => { }}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header>
                <Modal.Title>Finish Setup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className={`lead`}>Since you registered as freelancer, we need to setup a few more things!</p>
                {step === 1 && <>
                    <p>Step {step}</p>
                    <Form style={{ width: "unset" }}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Business / Company Name</Form.Label>
                            <br />
                            <small>You can also just use your full name here, this can be changed later.</small>
                            <Form.Control
                                className={`form-control form-control-solid`}
                                type="text"
                                placeholder="Jason's Photography"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Custom URL</Form.Label>
                            <Form.Control
                                className={`form-control form-control-solid`}
                                type="text"
                                placeholder="jasonphotography"
                                value={slug}
                                onChange={(e) => setSlug(e.target.value)}
                            />
                            {
                                slugMessage.show &&
                                <div className={slugMessage.status ? "valid-feedback" : "invalid-feedback"}>
                                    {slugMessage.message}
                                </div>
                            }

                            <br />
                            <small>Here's what your company URL would look like: <a href="#">https://lancely.co/{slug}</a></small>
                        </Form.Group>
                        <Button
                            variant="primary"
                            type="submit"
                            className={`btn btn-lg btn-primary fw-bolder me-3 my-2`}
                            onClick={handleSubmit}
                        >
                            Next
                        </Button>
                    </Form>
                </>}
                {step === 2 &&
                    <>
                        <p className={`lead`}>Awesome! Let's setup your merchant account now.</p>
                        <p>We use Stripe to handle all payments from your customers directly to your bank account. You can skip this step for now and continue at a later time but do keep in mind our amazing dispute handling services, accepting credit card payments, and a load of other features will not be available. On the other hand, if you would like to setup your account, click "Start Accepting Payments".</p>
                        <Button
                            variant="primary"
                            type="button"
                            className={`btn btn-lg btn-primary fw-bolder me-3 my-2`}
                            onClick={() => {
                                window.location = accountLink.url;
                            }}
                        >
                            Start Accepting Payments 
                        </Button>
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                {
                    step === 2 &&
                    <Button
                        variant="secondary"
                        type="button"
                        className={`btn btn-xs btn-primary me-3 my-2`}
                        onClick={() => {
                            completedSetup();
                        }}
                    >
                        Skip
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default SetupCompany