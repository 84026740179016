import React, { useState, useEffect, useContext } from 'react';

import lancely from '../../utils/lancely';
import { UserContext } from '../../context/UserContext';

import { Container, Row, Col, Card, Table } from 'react-bootstrap';
import UserView from './UserView/UserView';
import ChatView from './ChatView/ChatView';

function Chat({ navigation }) {

    const { state } = useContext(UserContext);

    if (state && !state.user) {
        return <div>Loading...</div>
    }

    return (
        <>
            <Container className={`mt-5 ps-5 pe-5`}>
                <Row>
                    <Col md={12}>
                        <p className={`display-5 fw-light`}>Chat</p>
                    </Col>
                    <Col md={3}>
                        <Card>
                            <Card.Body style={{ minHeight: '80vh', padding: 0 }}>
                                <UserView />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={9}>
                        <Card>
                            <Card.Body style={{ minHeight: '80vh' }}>
                                <ChatView />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default Chat