import React from 'react'
import { ChatFeed, Message } from 'react-chat-ui'


function ChatView() {
  return (
    <>
      <ChatFeed
        messages={[
          new Message({
            id: 1,
            message: "I'm the recipient! (The person you're talking to)",
            senderName: "Jack"
          }), // Gray bubble
          new Message({ id: 0, message: "I'm you -- the blue bubble!", senderName: "Elon"}), // Blue bubble
          new Message({ id: 0, message: "I'm you -- the blue bubble!", senderName: "Elon" }), // Blue bubble
          new Message({ id: 0, message: "I'm you -- the blue bubble!", senderName: "Elon" }), // Blue bubble
        ]} // Array: list of message objects
        isTyping={false} // Boolean: is the recipient typing
        hasInputField={false} // Boolean: use our input, or use your own
        showSenderName // show the name of the user who sent the message
        bubblesCentered={false} //Boolean should the bubbles be centered in the feed?
        // JSON: Custom bubble styles
        bubbleStyles={
          {
            text: {
              fontSize: 14
            },
            chatbubble: {
              borderRadius: 70,
              padding: 10
            }
          }
        }
      />

    </>
  )
}

export default ChatView