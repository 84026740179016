import React, { createContext, useReducer, useEffect } from "react";
import lancely from '../utils/lancely';

const initialState = {
    tokens: { access_token: null, refresh_token: null },
    user: null
};


const CLEAR = "CLEAR";
const SET_USER_DATA = "SET_ME_DATA";
const SET_TOKENS = "SET_ACCESS_TOKEN";


const UserContext = createContext(initialState);

const UserProvider = props => {

    const reducer = (state, action) => {
        let temp;
        switch (action.type) {
            case CLEAR:
                setUserContextData(initialState);
                return initialState;
            case SET_USER_DATA:
                temp = {
                    ...state,
                    user: {
                        ...state.user,
                        ...action.payload
                    }

                };
                setUserContextData(temp);
                return temp; 
            case SET_TOKENS:
                temp = {  
                    ...state,
                    tokens: {
                        ...state.tokens,
                        access_token: action.payload.access_token,
                        refresh_token: action.payload.refresh_token
                    }
                }
                setUserContextData(temp);
                return temp;
            default:
                return;
        }
    };

    const setUserContextData = (value) => {
        try {
            lancely.storage.set('auth', value);
        } catch (e) {
            // saving error
            console.log(e);
        }
    }

    const getUserContextData = () => {
        try {
            const value = lancely.storage.get('auth')
            if (value !== null) {
                // value previously stored
                return { ...initialState, ...value };
            }
        } catch (e) {
            // error reading value
            console.log(e);
        }
        return initialState;
    }


    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        let data = getUserContextData();
        if (data.user !== null) {
            dispatch({ type: SET_USER_DATA, payload: data.user });
        }
        if (data.tokens !== null) {
            dispatch({ type: SET_TOKENS, payload: data.tokens });
        }
    }, [])

    return (
        <UserContext.Provider value={{ state, dispatch }}>
            {props.children}
        </UserContext.Provider>
    );
};

export { UserContext, UserProvider, CLEAR, SET_USER_DATA, SET_TOKENS };
