import React, { useState, useEffect, useContext } from 'react'

import lancely from '../../../utils/lancely';
import { UserContext } from '../../../context/UserContext';

import { Form, Card, Button } from 'react-bootstrap';

import UserAvatar from 'react-user-avatar';
import { formatRelative } from 'date-fns';

function ProjectHome({ project }) {

	const [newPost, setNewPost] = useState('');
	const [threads, setThreads] = useState([]);
	const { state } = useContext(UserContext);

	const getThreads = () => {
		lancely.projects.threads.getAll(project._id)
			.then(resp => {
				setThreads(resp.threads);
			})
			.catch(err => {
				console.log(err);
			});
	}

	const createNewPost = (e) => {
		e.preventDefault();
		console.log(project);
		lancely.projects.threads.create(project._id, newPost)
			.then((resp) => {
				console.log(resp);
				getThreads();
				setNewPost("");
			})
			.catch(err => {
				console.log(err);
			})
	}

	useEffect(() => {
		getThreads();
	}, [])

	return (
		<>

			<Card>
				<Card.Body style={{ width: 'auto' }}>
					<Form style={{ width: '100%' }}>
						<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1" onSubmit={createNewPost}>
							<Form.Label>Update the Group...</Form.Label>
							<Form.Control
								as="textarea"
								rows={3}
								placeholder={`Hey everyone, let's meet around Harbourfront!`}
								value={newPost}
								onChange={(e) => setNewPost(e.target.value)}
							/>
						</Form.Group>
						<div className={`text-end`}>
							<Button
								variant="primary"
								type="submit"
								className={`btn btn-sm btn-primary fw-bolder`}
								onClick={createNewPost}
							>
								Post
							</Button>
						</div>
					</Form>
				</Card.Body>
			</Card>

			{
				threads && threads.length <= 0 &&
				<p className='lead m-5 text-center'>It's quiet here... You should create a new post!</p>
			}
			{
				threads && threads.map((thread, index) => {
					return <Card key={index} className={`mt-5 mb-5`}>
						<Card.Body>
							<p className='text-muted'>
								{formatRelative(new Date(thread.createdAt), new Date())}
							</p>
							<p className={`lead`}>{thread.content}</p>
							<div className={`d-flex flex-row align-items-center`}>
								<UserAvatar
									size="35"
									name={`${thread.owner.firstName} ${thread.owner.lastName}`}
									src={thread.owner.profilePicture !== "" ? thread.owner.profilePicture : "/assets/icons/user.png"}
									style={{ color: 'white' }}
								/>
								<p className='ms-2 mb-0 lead'>{thread.owner.firstName} {thread.owner.lastName}</p>
							</div>
							
						</Card.Body>
					</Card>
				})
			}
		</>
	)
}

export default ProjectHome