import React, { useState, useEffect, useContext } from 'react';

import lancely from '../../utils/lancely';
import { UserContext } from '../../context/UserContext';

import { Container, Row, Col, Card, Table } from 'react-bootstrap';


function Reports() {

    const { state } = useContext(UserContext);

    if (state && !state.user) {
        return <div>Loading...</div>
    }

    return (<>
        <Container className={`mt-5`}>
            <Row>
                <Col md={12}>
                    <p className={`display-5 fw-light`}>Reports</p>
                    <Card>
                        <Card.Body style={{ padding: 0 }}>
                            This is the reports page
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>

    </>);
}

export default Reports;
