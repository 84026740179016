import React from 'react'

import { Image } from 'react-bootstrap';

function UserItem({ user }) {
  return (
    <div
            className={`container-fluid`}
            onClick={() => {

            }}
            style={{
                cursor: 'pointer',
                // margin: 5
            }}
        >
            <div className={`row`}>
                {
                    user &&
                    (
                        <>
                            <div className={`col-4 text-end`}>
                                <Image
                                    style={{ width: 50, height: 50 }}
                                    src={'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png'}
                                    roundedCircle 
                                />
                            </div>
                            <div className={`col-8 g-0 d-flex justify-content-start align-items-center`}>
                                <div className={`d-flex flex-column g-0 p-0 m-0`}>
                                <p className={`m-0 fw-bold`}>{user.firstName} {user.lastName}</p>
                                <p className={`text-muted m-0`}>{user.message}</p>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </div>
  )
}

export default UserItem