import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import lancely from '../../utils/lancely';
import { UserContext } from '../../context/UserContext';

import {
    Container, Row, Col, Card,
    Table, Form, Button, FormControl, Alert
} from 'react-bootstrap';

import GenericModal from '../GenericModal';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import { toast } from 'react-toastify';

import 'react-multi-email/style.css';
import './Projects.css';

function Projects({ }) {

    const [searchParams] = useSearchParams();
    const { state } = useContext(UserContext);
    const navigate = useNavigate();

    const [projects, setProjects] = useState([]);
    const [page, setPage] = useState(0);
    const [emails, setEmails] = useState([]);
    const [showAlert, setShowAlert] = useState(true);
    const [filterArchived, setFilterArchived] = useState(false);

    const [showAddProject, setShowAddProject] = useState(false);
    const [addProjectFooter, setAddProjectFooter] = useState();
    const [addProjectStep, setAddProjectStep] = useState(1);

    const dateForDateTimeInputValue = date => new Date(date.getTime() + new Date().getTimezoneOffset() * -60 * 1000).toISOString().slice(0, 19)

    const [addProjectForm, setAddProjectForm] = useState({
        name: "",
        currency: "CAD",
        startDate: dateForDateTimeInputValue(new Date()),
        endDate: dateForDateTimeInputValue(new Date(new Date().getTime() + (1000 * 60 * 60 * 24)))
    })

    const steps = [
        <>
            <Form style={{ width: "unset" }}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Project Name</Form.Label>
                    <br />
                    <Form.Control
                        className={`form-control form-control-solid`}
                        type="text"
                        placeholder="Sunset Photoshoot"
                        value={addProjectForm.name}
                        onChange={(e) => setAddProjectForm({ ...addProjectForm, name: e.target.value })}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                        className={`form-control form-control-solid`}
                        type="datetime-local"
                        value={addProjectForm.startDate}
                        onChange={(e) => setAddProjectForm({ ...addProjectForm, startDate: e.target.value })}
                        min={dateForDateTimeInputValue(new Date())}
                    />
                    <br />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                        className={`form-control form-control-solid`}
                        type="datetime-local"
                        value={addProjectForm.endDate}
                        onChange={(e) => setAddProjectForm({ ...addProjectForm, endDate: e.target.value })}
                        min={dateForDateTimeInputValue(new Date(new Date().getTime() + (1000 * 60 * 60 * 24)))}
                    />
                    <br />
                </Form.Group>
            </Form>
        </>,
        <>
            <p>Invite people who are going to be part of the project</p>
            <small>This can be people who you are models or agencies that require access to the photos and videos that you take for this project.</small>
            <br /><br />
            <ReactMultiEmail
                placeholder="placeholder"
                emails={emails}
                onChange={(_emails) => {
                    setEmails(_emails);
                }}
                validateEmail={email => {
                    return isEmail(email); // return boolean
                }}
                getLabel={(
                    email,
                    index,
                    removeEmail = (index) => { }
                ) => {
                    return (
                        <div data-tag key={index}>
                            {email}
                            <span data-tag-handle onClick={() => removeEmail(index)}>
                                ×
                            </span>
                        </div>
                    );
                }}
            />
        </>
    ];

    const createProject = () => {
        // TODO: Show a loading screen.

        // FIXME: The emails are not added the first submit,
        // must be to do with react-multi-email not updating the
        // emails state.
        console.log(emails);

        // Make sure all the fields are filled out.
        if (addProjectForm.name === "" || addProjectForm.startDate === "" || addProjectForm.endDate === "") {
            toast.error("Please fill out all the fields.");
            return;
        }

        lancely.projects.create({
            ...addProjectForm,
            invites: emails
        })
            .then((resp) => {
                console.log(resp);
                setShowAddProject(false);
                updateProjectsList();
            })
    };

    // Pull data from API
    const updateProjectsList = () => {
        lancely.projects.getAll(page)
            .then(resp => {
                console.log(resp);
                setProjects(resp.projects);
            })
    }

    useEffect(() => {
        updateProjectsList();

        let checkout = searchParams.get('checkout');
        if (checkout && checkout === 'success') {

            // show toast saying payment was successful
            toast.success('Payment Successful!');

            // update invoice status:
            lancely.projects.invoices.update(searchParams.get('project_id'), searchParams.get('invoice_id'), { status: 'paid' })
                .then(resp => {
                    navigate(`/project/${searchParams.get('project_id')}`);
                });
        }

    }, [])

    useEffect(() => {
        if (showAddProject) {
            setAddProjectFooter(
                <>
                    <Button
                        variant="dark"
                        type="button"
                        className={`btn btn-xs btn-primary me-auto my-2`}
                        onClick={() => {
                            addProjectStep === 1 ? setShowAddProject(false) : setAddProjectStep(addProjectStep - 1)
                        }}
                    >
                        {addProjectStep === 1 ? 'Close' : 'Previous'}
                    </Button>
                    <Button
                        variant="primary"
                        type="button"
                        className={`btn btn-xs btn-primary me-3 my-2`}
                        onClick={() => {
                            addProjectStep !== steps.length ? setAddProjectStep(addProjectStep + 1) : createProject()
                        }}
                    >
                        {addProjectStep === 1 || (addProjectStep < steps.length) ? 'Next' : 'Finish'}
                    </Button>
                </>
            )
        }
    }, [showAddProject, addProjectStep])

    // a and b are javascript Date objects
    function dateDiffInDays(a, b) {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // Discard the time and time-zone information.
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }

    const toggleAddProject = () => {
        setAddProjectStep(1);
        setShowAddProject(!showAddProject);
    }

    if (state && !state.user) {
        return <div>Loading...</div>
    }

    return (
        <>
            <Container className={`mt-5`}>
                <Row>
                    <Col md={12}>

                        {
                            state.user.companies[0]?.stripeConnectOnboarded === false &&
                            <Alert variant={`primary`}>
                                You haven't finished setting up your merchant account, so you will not be able to accept payments. <Alert.Link href="#" onClick={() => { navigate('/settings') }}>Finish setup.</Alert.Link>
                            </Alert>
                        }

                        <div className={`d-flex justify-content-center`}>
                            <div className={`col-md-10`}>
                                <p className={`display-5 fw-light`}>Projects</p>
                            </div>
                            <div className={`col-md-2`}>
                                <div className="text-end">
                                    {/* react bootstrap search form */}
                                    <Button
                                        variant="primary"
                                        type="button"
                                        className={`btn btn-primary btn-sm mb-5`}
                                        onClick={() => {
                                            toggleAddProject()
                                        }}
                                    >
                                        New Project
                                    </Button>
                                </div>
                            </div>
                        </div>

                        {/* <div style={{ display: 'inline' }}> */}
                        {/* <p className={`lead`}>{projects.length} Projects</p> */}
                        <p className={`lead`}>
                            {/* create react bootstrap checkbox */}
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label="Show Archived"
                                checked={filterArchived}
                                onChange={() => {
                                    setFilterArchived(!filterArchived);
                                }}
                            />
                        </p>
                        {/* </div> */}

                        {/* TODO: Enable search functions */}
                        {/* <Form className="d-flex m-4">
                            <FormControl
                                type="search"
                                placeholder="Search"
                                // className="me-2"
                                aria-label="Search"
                            />
                        </Form> */}

                        <Card>
                            <Card.Body style={{}}>
                                {projects.length === 0  && <p className='lead'>You don't have any projects! Let's <a href="#" onClick={toggleAddProject}>create one.</a></p>}

                                {
                                    projects.length > 0 &&
                                    <Table responsive hover>
                                        <thead>
                                            <tr>
                                                {/* <th>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" />
                                                </div>
                                            </th> */}
                                                <th>Project Title</th>
                                                <th>Status</th>
                                                <th>Role</th>
                                                <th>Forecast</th>
                                                {/* <th>Recent Activity</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {projects.map((project, index) => {
                                                let totalDays = dateDiffInDays(new Date(project.startDate), new Date(project.endDate));
                                                let elapsedDays = dateDiffInDays(new Date(project.startDate), new Date());
                                                if (filterArchived === false && project.status !== "active") {
                                                    return null;
                                                }
                                                return (
                                                    <tr key={index} style={{ cursor: "pointer" }} onClick={() => {
                                                        navigate(`/project/${project._id}`)
                                                    }}>
                                                        {/* <th>
                                                        <div className="form-check">
                                                            <input id={`project-checkbox-${index}`} className="form-check-input" type="checkbox" value="" />
                                                        </div>
                                                    </th> */}
                                                        <td>{project.name}</td>
                                                        <td>{project.status}</td>
                                                        <td>{project.members.filter(a => a.role === 'owner' && a.member === state.user._id).length > 0 ? "Owner" : "Client"}</td>
                                                        <td>{totalDays - elapsedDays < 0 ? "Overdue" : `${totalDays - elapsedDays} days left`}</td>
                                                        {/* <td>{
                                                            [
                                                                "You uploaded a new image",
                                                                "Sara liked a new photo",
                                                                "Sogand requested a new image",
                                                                "Ali made a payment",
                                                                "Bruktawit viewed the invoice",
                                                                "5 new likes",
                                                                "Ali changed an upcoming event"
                                                            ].sort((a, b) => 0.5 - Math.random())[0]
                                                        }</td> */}
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <GenericModal
                show={showAddProject}
                onHide={toggleAddProject}
                title={"Create a New Project"}
                footer={addProjectFooter}
                backdrop={'static'}
            >
                {steps[addProjectStep - 1]}
            </GenericModal>
        </>
    )
}

export default Projects;
