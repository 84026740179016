import React, { useEffect, useState } from 'react'
import lancely from '../../../utils/lancely';

import { Modal } from 'react-bootstrap';

// import date-fns format
import { format } from 'date-fns';

import { toast } from 'react-toastify';

function ProjectPayments({ project, user }) {

  const member = project.members.find((member => member.member._id === user._id));
  const otherMembers = project.members.filter((member => member.member._id !== user._id));
  const isOwner = !!project.members.filter(member => member.member._id === user._id && member.role === 'owner').length;

  const [invoices, setInvoices] = useState([]);
  const [showCreateInvoiceModal, setShowCreateInvoiceModal] = useState(false);
  const [showInvoiceDetails, setShowInvoiceDetails] = useState(false);
  const [formData, setFormData] = useState({});
  const [invoiceItem, setInvoiceItem] = useState({});
  const [invoiceItems, setInvoiceItems] = useState([]);

  useEffect(() => {
    getInvoices();
  }, [])

  const getInvoices = () => {
    lancely.projects.invoices.getAll(project._id)
      .then(resp => {
        setInvoices(resp.invoices);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }

  const handleChangeItems = (e) => {
    setInvoiceItem({
      ...invoiceItem,
      [e.target.name]: e.target.value
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      ...formData,
      invoiceItems: invoiceItems
    };
    lancely.projects.invoices.create(project._id, data)
      .then(resp => {
        getInvoices();
        setFormData({});
        setInvoiceItems([]);
        setShowCreateInvoiceModal(false);
      })
      .catch(err => {
        console.log(err);
      });
    console.log(data);
  }

  const deleteInvoice = (invoiceId) => {
    lancely.projects.invoices.delete(project._id, invoiceId)
      .then(resp => {
        getInvoices();
      })
      .catch(err => {
        console.log(err);
      });
  }

  const payInvoice = (invoiceId) => {
    lancely.projects.invoices.pay(project._id, invoiceId)
      .then(resp => {
        // getInvoices();
        // get the URL:
        if (resp.url) {
          window.location = resp.url;
        } else {
          toast.error('There was an error with our payment service, please try again later.');
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  const sendInvoice = (invoiceId) => {
    lancely.projects.invoices.update(project._id, invoiceId, { status: 'sent' })
      .then(resp => {
        getInvoices();
      })
      .catch(err => {
        console.log(err);
      });
  }


  return (
    <>
      {isOwner && invoices.length <= 0 && (
        <p className='lead text-center'>
          Here you can create invoices for your clients and bill them directly.
        </p>
      )}
      {!isOwner && invoices.length <= 0 && (
        <p className='lead text-center'>
          You don't have any invoices yet.
        </p>
      )}

      {invoices.length > 0 && (
        <table className='table table-striped'>
          <thead>
            <tr>
              <th>User</th>
              <th>Date</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {invoices.filter((invoice) => isOwner || ['unsent', 'sent', 'paid'].includes(invoice.status)).map(invoice => (
              <tr key={invoice._id}>
                <td>{invoice.invoiceTo.firstName} {invoice.invoiceTo.lastName}</td>
                <td>{format(new Date(invoice.dueOn), 'MMMM d, yyyy')}</td>
                <td>
                  {/* add all invoice.invoiceItems.price with tax */}
                  ${invoice.invoiceItems.reduce((acc, item) => {
                    return acc + (item.price * item.quantity * 1.05);
                  }, 0).toFixed(2)}
                </td>
                <td>{invoice.status}</td>
                <td>
                  <button className='btn btn-sm btn-primary m-1' onClick={() => { setShowInvoiceDetails(invoice) }}>View</button>
                  {
                    isOwner && (
                      <>
                        {['draft', 'unsent'].includes(invoice.status) && <button className='btn btn-sm btn-success m-1'
                          onClick={() => {
                            sendInvoice(invoice._id);
                          }}
                        >Send</button>}
                        {['draft', 'sent'].includes(invoice.status) && <button className='btn btn-sm btn-danger m-1' onClick={() => { deleteInvoice(invoice._id) }}>Delete</button>}
                      </>
                    )
                  }
                  {
                    !isOwner && (
                      <>
                        {invoice.status === 'sent' && <button className='btn btn-sm btn-success m-1' onClick={() => { payInvoice(invoice._id) }}>Pay</button>}
                      </>
                    )
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* show invoice details modal */}
      <Modal show={showInvoiceDetails} onHide={() => { setShowInvoiceDetails(false); }}>
        <Modal.Header closeButton>
          <Modal.Title>Invoice #{showInvoiceDetails?._id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            showInvoiceDetails && showInvoiceDetails._id && <>
              <div className='row'>
                <div className='col-md-6'>
                  <h5>Invoice To</h5>
                  <p>{showInvoiceDetails?.invoiceTo.firstName} {showInvoiceDetails?.invoiceTo.lastName}</p>
                  <p>{showInvoiceDetails?.invoiceTo.email}</p>
                </div>
                <div className='col-md-6'>
                  <h5>Invoice From</h5>
                  <p>{showInvoiceDetails?.owner.firstName} {showInvoiceDetails?.owner.lastName}</p>
                  <p>{showInvoiceDetails?.owner.email}</p>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <h5>Invoice Date</h5>
                  <p>{format(new Date(showInvoiceDetails?.createdAt), 'MMMM d, yyyy')}</p>
                </div>
                <div className='col-md-6'>
                  <h5>Due Date</h5>
                  <p>{format(new Date(showInvoiceDetails?.dueOn), 'MMMM d, yyyy')}</p>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <h5>Status</h5>
                  <p>{showInvoiceDetails?.status}</p>
                </div>
                <div className='col-md-6'>
                  <h5>Amount</h5>
                  <p>${showInvoiceDetails?.invoiceItems.reduce((acc, item) => {
                    return acc + (item.price * item.quantity * 1.05);
                  }, 0).toFixed(2)}</p>
                </div>
              </div>
            </>
          }
        </Modal.Body>
      </Modal>

      {
        isOwner && (
          <>
            <button className={`btn btn-primary text-center`} onClick={() => { setShowCreateInvoiceModal(true); }}>Create Invoice</button>
            <Modal show={showCreateInvoiceModal} onHide={() => { setShowCreateInvoiceModal(false); }} size="lg" centered>
              <Modal.Header closeButton>
                <Modal.Title>Create Invoice</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className={`card shadow mb-4`}>
                        <div className={`card-body`}>
                          <p className={`lead`}>Select files for upload (max 10):</p>
                          <form onSubmit={handleSubmit}>
                            <div className={`form-group`}>
                              {/* Dropdown of otherMembers */}
                              <select className={`form-control`} name="invoiceTo" onChange={handleChange}>
                                <option value="">Select a member</option>
                                {otherMembers.map((member, index) => {
                                  return (
                                    <option key={index} value={member.member._id}>{member.member.firstName} {member.member.lastName} ({member.member.email})</option>
                                  )
                                })}
                              </select>
                            </div>
                            {/* create date dueOn selection form input */}
                            <div className={`form-group`}>
                              <label htmlFor="dueOn">Due On</label>
                              <input type="date" className={`form-control`} name="dueOn" onChange={handleChange} />
                            </div>
                            {/* create invoice status selection with options: 'draft', 'unsent', 'sent', 'viewed', 'partial', 'paid', 'overdue' */}
                            <div className={`form-group`}>
                              <label htmlFor="status">Status</label>
                              <select className={`form-control`} name="status" onChange={handleChange}>
                                <option value="draft">Draft</option>
                                <option value="unsent">Unsent</option>
                                <option value="sent">Sent</option>
                                <option value="paid">Paid</option>
                              </select>
                            </div>
                            {/* create invoice notes textarea */}
                            <div className={`form-group`}>
                              <label htmlFor="notes">Notes</label>
                              <textarea className={`form-control`} name="notes" onChange={handleChange}></textarea>
                            </div>
                            {/* create hidden field with interval set to once */}
                            <input type="hidden" name="interval" value="once" />

                            {/* create list from invoiceItems */}
                            {
                              invoiceItems && invoiceItems.length > 0 &&
                              <div className={`form-group`}>
                                <label htmlFor="invoiceItems">Invoice Items</label>
                                <ul className={`list-group border-0`}>
                                  <li className={`list-group-item`}>
                                    <div className={`row`}>
                                      <div className={`col-md-5`}>
                                        <p>Name</p>
                                      </div>
                                      <div className={`col-md-2`}>
                                        <p>Price</p>
                                      </div>
                                      <div className={`col-md-2`}>
                                        <p>Quantity</p>
                                      </div>
                                      <div className={`col-md-3`}>
                                        Options
                                      </div>
                                    </div>
                                  </li>
                                  {invoiceItems.map((item, index) => {
                                    return (
                                      <li className={`list-group-item`} key={index}>
                                        <div className={`row`}>
                                          <div className={`col-md-5`}>
                                            <p className={`lead`}>{item.name}</p>
                                          </div>
                                          <div className={`col-md-2`}>
                                            <p className={`lead`}>${item.price}</p>
                                          </div>
                                          <div className={`col-md-2`}>
                                            <p className={`lead`}>{item.quantity}</p>
                                          </div>
                                          <div className={`col-md-3`}>
                                            <button className={`btn btn-danger`} onClick={() => {
                                              setInvoiceItems(invoiceItems.filter((_item, _index) => {
                                                return _index !== index;
                                              }))
                                            }}>Remove</button>
                                          </div>
                                        </div>
                                      </li>
                                    )
                                  })}
                                </ul>
                              </div>
                            }

                            {/* create dynamic inputs with item name, price and quantity */}
                            <div className={`form-group`}>
                              <label htmlFor="items">New Item</label>
                              <div className={`row`}>
                                <div className={`col-md-5`}>
                                  <input type="text" className={`form-control`} name="name" value={invoiceItem?.name} onChange={handleChangeItems} placeholder="Name" />
                                </div>
                                <div className={`col-md-2`}>
                                  <input type="number" className={`form-control`} name="price" value={invoiceItem?.price} onChange={handleChangeItems} placeholder="Price" />
                                </div>
                                <div className={`col-md-2`}>
                                  <input type="number" className={`form-control`} name="quantity" value={invoiceItem?.quantity} onChange={handleChangeItems} placeholder="Quantity" />
                                </div>
                                <div className={`col-md-3`}>
                                  <button type="button" className={`btn btn-primary`} onClick={() => {
                                    if (invoiceItem?.name && invoiceItem?.price && invoiceItem?.quantity) {
                                      setInvoiceItems([...invoiceItems, invoiceItem]); setInvoiceItem({ name: '', price: 0, quantity: 0 })
                                    }
                                  }}>Add Item</button>
                                </div>
                              </div>
                            </div>
                            <div className={`form-group mt-5 text-center`}>
                              <button type="submit" className="btn btn-primary">Create Invoice</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {/* InvoiceItems total */}
                <div className={`row`}>
                  <div className={`col-md-12 text-end`}>
                    <p className={`lead`}>Subtotal: ${invoiceItems?.reduce((acc, item) => {
                      return acc + (item.price * item.quantity);
                    }, 0).toFixed(2)}</p>
                    <p className={`lead`}>Tax: ${invoiceItems?.reduce((acc, item) => {
                      return acc + (item.price * item.quantity * 0.05);
                    }, 0).toFixed(2)}</p>
                    <p className={`lead`}>Total: ${invoiceItems?.reduce((acc, item) => {
                      return acc + (item.price * item.quantity * 1.05);
                    }, 0).toFixed(2)}</p>
                  </div>
                </div>
              </Modal.Footer>
            </Modal>
          </>
        )
      }

    </>
  )
}

export default ProjectPayments