import React from 'react'

import { Modal } from 'react-bootstrap';


function GenericModal({ backdrop, show, onHide, title, children, footer }) {
	return (
		<Modal
			show={show}
			onHide={onHide}
			backdrop={backdrop || true}
			keyboard={false}
			centered
		>
			<Modal.Header>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{children}
			</Modal.Body>
			{footer && (
				<Modal.Footer>
					{footer}
				</Modal.Footer>
			)}
		</Modal>
	)
}

export default GenericModal