import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import useWindowDimensions from '../../utils/useWindowDimensions';

import lancely from '../../utils/lancely';

import { Form, Button } from 'react-bootstrap';
import { FcGoogle } from 'react-icons/fc';
import '../Login/Login.css';

const Register = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({ firstName: "", lastName: "", email: "", password: "" });
    const [accountType, setAccountType] = useState("client");

    const { width } = useWindowDimensions();

    const handleSubmit = (e) => {
        // Form Validation
        e.preventDefault();
        if (formData.firstName.trim() === "") {
            toast.error("First name is required");
            return;
        } else if (formData.lastName.trim() === "") {
            toast.error("Last name is required");
            return;
        } else if (formData.email.trim() === "") {
            toast.error("Email is required");
            return;
        } else if (formData.password.trim() === "") {
            toast.error("Password is required");
            return;
        }
        lancely.users.signup(formData.firstName, formData.lastName, formData.email, formData.password, accountType)
            .then(resp => {
                navigate('/');
            })
            .catch(err => {
                // console.log(err.response);
                if (err?.response?.data?.code === 11000) {
                    toast.error("This email address is already taken, please login or try again with a new email.")
                }
            })
    }

    return (
        <div>
            <div className={`container-fluid`}>
                <div className={`row g-0 d-flex justify-content-center align-items-center`} style={{ height: width > 768 ? '100vh' : '' }}>
                    <div className={`col-md-5 p-5 d-flex flex-column justify-content-center align-items-center text-center bg-primary text-light h-100`}>
                        <h1 className={`fw-bolder text-white fs-2qx pb-5 pb-md-10`}>Welcome to Lancely</h1>
                        <p className={`lead fw-bold`}>Let's get you started so that you can handle appointments, payments, scheduling, disputes, and everything else under one application.</p>
                        <img className={`img-fluid w-75 d-none d-md-block`} alt={`Solutions`} src={`/assets/icons/solutions.svg`} />
                    </div>
                    <div className={`col-md-7 mx-auto`}>
                        <div className={`d-flex flex-column justify-content-center align-items-center`} style={{ marginTop: width > 768 ? '' : '5vh' }}>
                            <h1 className={`text-dark mb-3`}>Create an Account</h1>
                            <p className={`text-gray-400 fw-bold fs-4`}>Already have an account? <Link to="/">Login Here</Link></p>
                            {/* <Button
                                className={`btn-light-primary btn-lg btn-block fw-bolder my-2`}
                                onClick={() => { toast.dark("Coming soon...") }}
                            >
                                <FcGoogle />
                                Sign up with Google
                            </Button> */}
                            <br />
                            
                            <p className={`lead text-center`}>Are you signing up as a freelancer or client?</p>
                            <div className={`text-center`}>
                                <Button
                                    variant={accountType === "freelancer" ? "primary" : ""}
                                    type={`button`}
                                    className={`btn btn-lg ${accountType === "freelancer" ? "btn-primary" : "btn-light-primary"} fw-bolder me-3 my-2`}
                                    onClick={() => { setAccountType('freelancer') }}
                                >
                                    Freelancer
                                </Button>
                                <Button
                                    type={`button`}
                                    className={`btn ${accountType !== "freelancer" ? "btn-primary" : "btn-light-primary"} btn-lg fw-bolder my-2`}
                                    onClick={() => { setAccountType('client') }}
                                >
                                    Client
                                </Button>
                            </div>
                            <div className="d-flex flex-row justify-content-center align-items-center w-100 mt-10 mb-10">
                                <div className="border-bottom border-gray-300 mw-50 w-25"></div>
                                {/* <span className="fw-bold text-gray-400 fs-7 mx-2">AND</span> */}
                                <div className="border-bottom border-gray-300 mw-50 w-25"></div>
                            </div>
                            <Form onSubmit={handleSubmit}>
                                <div className={`row`}>
                                    <div className={`col-md-6`}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control
                                                className={`form-control form-control-lg form-control-solid`}
                                                type="text"
                                                placeholder="John"
                                                value={formData.firstName}
                                                onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className={`col-md-6`}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control
                                                className={`form-control form-control-lg form-control-solid`}
                                                type="text"
                                                placeholder="Smith"
                                                value={formData.lastName}
                                                onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                                            />
                                        </Form.Group>
                                    </div>


                                </div>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control
                                        className={`form-control form-control-lg form-control-solid`}
                                        type="email"
                                        placeholder="john.smith@lancely.co"
                                        value={formData.email}
                                        onChange={(e) => setFormData({ ...formData, email: e.target.value.trim() })}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        className={`form-control form-control-lg form-control-solid`}
                                        type="password"
                                        placeholder="Password"
                                        value={formData.password}
                                        onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                                    />
                                </Form.Group>
                                <small className={`text-muted text-left`}>By clicking "Sign Up" you agree to our Terms and Conditions and Privacy Policy. Please make sure you read and understand them.</small>
                                <div className={`text-center`}>
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        className={`btn btn-lg btn-primary fw-bolder me-3 my-2`}
                                    >
                                        Sign Up
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register
