import React, { useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import useWindowDimensions from '../../utils/useWindowDimensions';

// API
import lancely from '../../utils/lancely';

import { Form, Button } from 'react-bootstrap';
import { FcGoogle } from 'react-icons/fc';
import './Login.css';

import { SET_USER_DATA, SET_TOKENS, UserContext } from "../../context/UserContext";

const Login = (props) => {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const { width } = useWindowDimensions();

    // So that the rest of the application can
    // get updated if the user has logged in
    const { dispatch } = useContext(UserContext);

    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (email.trim() === "") {
            toast.error("Email is required");
            return;
        } else if (password === "") {
            toast.error("Password is required");
            return;
        }
        lancely.users.login(email, password)
            .then(resp => {
                if (resp.status === true) {
                    console.log(resp);
                    // Data is saved to then context
                    // and the storage is updated
                    // with user information.
                    dispatch({
                        type: SET_USER_DATA,
                        payload: { ...resp.user },
                    });
                    dispatch({
                        type: SET_TOKENS,
                        payload: { ...resp.tokens },
                    });
                    // Navigate to home
                    navigate('/');
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <div>
            <div className={`container-fluid`}>
                <div className={`row g-0 d-flex justify-content-center align-items-center`} style={{ height: width > 768 ? '100vh' : '' }}>
                    <div className={`col-md-5 p-5 d-flex flex-column justify-content-center align-items-center text-center bg-primary text-light h-100`}>
                        <h1 className={`fw-bolder text-white fs-2qx pb-5 pb-md-10`}>Welcome to Lancely</h1>
                        <p className={`lead fw-bold`}>Let's get you started so that you can handle appointments, payments, scheduling, disputes, and everything else under one application.</p>
                        <img className={`img-fluid w-75 d-none d-md-block`} alt={`Solutions`} src={`/assets/icons/solutions.svg`} />

                    </div>
                    <div className={`col-md-7 mx-auto`}>
                        <div className={`d-flex flex-column justify-content-center align-items-center`} style={{ marginTop: width > 768 ? '' : '5vh' }}>
                            <h1 className={`text-dark mb-3`}>Sign In to Lancely</h1>
                            <p className={`text-gray-400 fw-bold fs-4`}>Don't have an account? <Link to="/register">Create one!</Link></p>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control
                                        className={`form-control form-control-lg form-control-solid`}
                                        type="email"
                                        placeholder="Enter email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        className={`form-control form-control-lg form-control-solid`}
                                        type="password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <p className={`text-gray-400 text-end`}><Link to="/reset">Forgot Password?</Link></p>
                                </Form.Group>
                                <div className={`text-center`}>
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        className={`btn btn-lg btn-primary fw-bolder me-3 my-2`}
                                        onClick={handleSubmit}
                                    >
                                        Log In
                                    </Button>
                                    {/* <Button
                                        className={`btn btn-light-primary btn-lg fw-bolder my-2`}
                                        onClick={() => { toast.dark("Coming soon...") }}
                                    >
                                        <FcGoogle />
                                        Sign in with Google
                                    </Button> */}
                                </div>
                            </Form>
                            {/* <div className="d-flex flex-row justify-content-center align-items-center w-100 mt-10 mb-10">
                                <div className="border-bottom border-gray-300 mw-50 w-25"></div>
                                <span className="fw-bold text-gray-400 fs-7 mx-2">OR</span>
                                <div className="border-bottom border-gray-300 mw-50 w-25"></div>
                            </div> */}
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;