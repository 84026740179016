import React, { useState, useEffect, useContext } from 'react';

import lancely from '../../utils/lancely';
import { UserContext } from '../../context/UserContext';

import { Container, Row, Col, Card, Table } from 'react-bootstrap';

import './Settings.css';

function Settings({ navigation }) {

    const { state } = useContext(UserContext);

    if (state && !state.user) {
        return <div>Loading...</div>
    }

    return (<>
        <Container className={`mt-5`}>
            <Row>
                <Col md={12}>
                    <p className={`display-5 fw-light`}>Settings</p>
                    <Card>
                        <Card.Body style={{}}>
                            <div className="settingsView p-1 mt-2">
                                <h5>Preferences</h5>
                                <p>Here you can edit your profile, username, change your number, set up notifications, and change the Lancely appearance.</p>

                                <div className="row">
                                    <div className="col-md-4">
                                        <div>Edit Profile</div>
                                    </div>
                                    <div className="col-md-4">
                                        <div>
                                            Notifications
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div>Appearance</div>
                                    </div>
                                </div>

                                <h5>Branding</h5>
                                <p>Here you can set up watermarks for uploaded images and custom email parameters.</p>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div>Logo / Watermarks</div>
                                    </div>
                                    <div className="col-md-4">
                                        <div>Custom Domain</div>
                                    </div>
                                    <div className="col-md-4">
                                        <div>Emails</div>
                                    </div>
                                </div>

                                <h5>Card <sup className="text-muted">Coming Soon</sup></h5>
                                <p>Coming soon, the Lancely Pre-paid Card</p>

                                <div className="spacer"></div>

                                <h5>Billing</h5>
                                <p>Sign up for a premium or ultimate plan, accept payments, or review your payments history!</p>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div>Subscription</div>
                                    </div>
                                    <div className="col-md-4 {{'data-disabled=true' if user.status not in ['invite', 'invited']}}">
                                        <div>Accept Payments</div>
                                    </div>
                                    <div className="col-md-4">
                                        <div>History</div>
                                    </div>
                                </div>

                                <h5>Other</h5>
                                <p>Security settings, 2FA, and other helpful links!</p>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div>Logged in Devices</div>
                                    </div>
                                    <div className="col-md-4">
                                        <div>Two Factor Authentication (2FA)</div>
                                    </div>
                                    <div className="col-md-4">
                                        <div>Help Centre</div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>

    </>);
}

export default Settings;
