import React, { useState, useEffect } from 'react'

import lancely from '../../../utils/lancely'

import Kalend, { CalendarView } from 'kalend' // import component
import 'kalend/dist/styles/index.css'; // import styles
import '../../Events/Events.css';

function ProjectEvents({ project, user, updateProject }) {

    const [events, setEvents] = useState([]);
    const projectColorMappings = [
        "#0d6efd",
        "#6610f2",
        "#6f42c1",
        "#d63384",
        "#dc3545",
        "#fd7e14",
        "#ffc107",
        "#198754",
        "#6c757d",
        "#17a2b8",
    ];

    const getEventsForProject = () => {
        lancely.events.getAllByProject(project._id)
            .then(resp => {
                const modifiedEvents = resp.events.map((event, index) => {
                    return {
                        ...event,
                        summary: event.name,
                        startAt: event.startDate,
                        endAt: event.endDate,
                        color: projectColorMappings[index % projectColorMappings.length],
                    }

                })
                setEvents(modifiedEvents);
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        getEventsForProject();
    }, [])

    return (
        <>
            <Kalend
                onEventClick={(event) => { }}
                onNewEventClick={() => { }}
                events={events}
                initialDate={new Date().toISOString()}
                hourHeight={60}
                initialView={CalendarView.WEEK}
                // disabledViews={[CalendarView.DAY]}
                // onSelectView={onSelectView}
                // selectedView={selectedView}
                // onPageChange={onPageChange}
                timeFormat={'24'}
                weekDayStart={'Sunday'}
                calendarIDsHidden={['work']}
                language={'en'}
            />
        </>
    )
}

export default ProjectEvents