import React, { useEffect, useState, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import lancely from '../../../utils/lancely';
import { UserContext } from '../../../context/UserContext';

import {
    Container, Row, Col, Card, Form, Button, Badge, Nav
} from 'react-bootstrap';
import UserAvatar from 'react-user-avatar';
import GenericModal from '../../GenericModal';

import { format } from 'date-fns';
import { ReactMultiEmail, isEmail } from 'react-multi-email';

import ProjectHome from './ProjectHome';

import 'react-multi-email/style.css';
import ProjectEvents from './ProjectEvents';
import ProjectFiles from './ProjectFiles';
import ProjectPayments from './ProjectPayments';
import ProjectSettings from './ProjectSettings';

function ProjectView() {

    let { projectId } = useParams();

    const { state } = useContext(UserContext);
    const navigate = useNavigate();

    const [emails, setEmails] = useState([]);
    const [project, setProject] = useState({});
    const [showInvite, setShowInvite] = useState(false);
    const [error, setError] = useState(null);
    const projectStatus = {
        'active': 'primary',
        'suspended': 'danger',
        'inactive': 'warning',
        'completed': 'success'
    };
    const [showSidebar, setShowSidebar] = useState(true);
    const [viewComponent, setViewComponent] = useState(<p>Loading...</p>);

    const getProjectDetails = (update = true) => {
        lancely.projects.get(projectId)
            .then(resp => {
                if (resp.status) {
                    setProject({
                        ...project,
                        ...resp.project
                    });
                    if (update) {
                        setViewComponent(<ProjectHome threads={resp.project.threads} project={resp.project} />)
                    }
                } else {
                    setError(resp.message);
                }
            })
            .catch(err => {
                console.log(err);
                setError(err.message);
            });
    }

    const inviteMembers = () => {
        lancely.projects.members.add(projectId, { emails: emails })
            .then(resp => {
                console.log(resp);
                getProjectDetails();
            })
            .catch(err => {
                console.log(err);
            });
    }


    useEffect(() => {
        getProjectDetails();

    }, [])

    if (error) {
        return <div>{error}</div>
    }

    if (Object.keys(project).length === 0) {
        return <div>Loading...</div>
    }

    const navLinks = [
        {
            isShown: true,
            disabled: false,
            hideSidebar: false,
            name: 'Home',
            component: <ProjectHome threads={project.threads} project={project} />
        },
        {
            isShown: true,
            disabled: false,
            hideSidebar: true,
            name: 'Events',
            component: <ProjectEvents project={project} user={state.user} updateProject={getProjectDetails} />,
        },
        {
            isShown: true,
            disabled: false,
            hideSidebar: true,
            name: 'Files',
            component: <ProjectFiles project={project} user={state.user} updateProject={getProjectDetails} />,
        },
        {
            isShown: project.members.filter((member => {
                return member.member?._id === state?.user?._id && (member.isPaying === true || member.role === 'owner')
            })).length > 0,
            disabled: state.user?.companies[0]?.stripeConnectOnboarded === false ? false : false,
            hideSidebar: false,
            name: 'Payments',
            component: <ProjectPayments project={project} user={state.user} />
        },
        {
            isShown: project.owner.owner === state.user._id,
            disabled: false,
            hideSidebar: false,
            name: 'Settings',
            component: <ProjectSettings project={project} user={state.user} setShowInvite={setShowInvite} getProjectDetails={getProjectDetails} />
        }
    ];

    return (
        <>
            <Container className={`mt-5`}>
                <Row>
                    <Col md={12}>
                        <div className={'d-flex align-items-center mb-5'}>
                            <span className={`display-6 fw-normal`}>
                                {project.name}
                            </span>
                            <span className={'me-3'}>&nbsp;</span>
                            <Badge bg={projectStatus[project.status]}>{project.status}</Badge>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} lg={3} className={`d-none ${showSidebar ? 'd-lg-block' : ''}`}>
                        <Card>
                            <Card.Body style={{}}>
                                <p className="lead fw-bold">Details</p>
                                <p>Status of Project: {project.status}</p>
                                <p>Start Date: {format(new Date(project.startDate), 'MMM do, yyyy')}</p>
                                <p>End Date: {format(new Date(project.endDate), 'MMM do, yyyy')}</p>
                                <p className={`d-flex justify-content-between`}>
                                    <span className="lead fw-bold">Members</span>
                                    <span className={`fw-bold text-primary fs-3`} style={{ cursor: 'pointer' }} onClick={() => { setShowInvite(true); }}>+</span>
                                </p>
                                <div className={'d-flex justify-content-evenly'}>
                                    {
                                        project.members.length <= 1 && <>
                                            <small>It's quiet here... <a href="#"
                                                onClick={() => {
                                                    setShowInvite(true);
                                                }}
                                            >invite someone</a>!
                                            </small>
                                        </>
                                    }
                                    {project.members.filter((member => member.member?._id !== state.user?._id)).map((member, index) => {
                                        if (member.member.profilePicture) {
                                            return <UserAvatar
                                                key={index}
                                                size="38"
                                                name={`${member.member.firstName} ${member.member.lastName}`}
                                                src={member.member.profilePicture}
                                                style={{ color: 'white' }}
                                            />
                                        } else {
                                            return <UserAvatar
                                                key={index}
                                                size="38"
                                                name={`${member.member.firstName} ${member.member.lastName}`}
                                                style={{ color: 'white' }}
                                            />
                                        }
                                    })}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} lg={showSidebar ? 9 : 12}>
                        <Card>
                            <Card.Body style={{}}>
                                <Nav className="justify-content-center">
                                    {
                                        navLinks.map((navLink, index) => {
                                            if (navLink.isShown) {
                                                return <Nav.Item key={index}>
                                                    <Nav.Link disabled={navLink.disabled} onClick={() => {
                                                        setShowSidebar(!navLink.hideSidebar);
                                                        setViewComponent(navLink.component)
                                                    }}>
                                                        {navLink.name}
                                                    </Nav.Link>
                                                </Nav.Item>
                                            }
                                        })
                                    }
                                </Nav>
                                <br />
                                {viewComponent}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <GenericModal
                show={showInvite}
                onHide={() => { setShowInvite(false); }}
                title={"Invite Members"}
            // backdrop={'static'}
            >
                <ReactMultiEmail
                    placeholder="example@lancely.co"
                    emails={emails}
                    onChange={(_emails) => {
                        setEmails([..._emails]);
                    }}
                    validateEmail={email => {
                        return isEmail(email); // return boolean
                    }}
                    getLabel={(
                        email,
                        index,
                        removeEmail = (index) => { }
                    ) => {
                        return (
                            <div data-tag key={index}>
                                {email}
                                <span data-tag-handle onClick={() => removeEmail(index)}>
                                    ×
                                </span>
                            </div>
                        );
                    }}
                />
                <div className="d-flex justify-content-end">
                    <Button variant="primary" onClick={() => {
                        setShowInvite(false);
                        setEmails([]);
                    }}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => {
                        inviteMembers(project._id, emails);
                        setShowInvite(false);
                        // setEmails([]);
                    }}>
                        Invite
                    </Button>
                </div>
            </GenericModal>
        </>
    )
}

export default ProjectView