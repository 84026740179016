import React, { useContext } from "react";
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';

import { Link, useNavigate, useLocation } from 'react-router-dom';

import { UserContext } from "../../context/UserContext";

import Image from 'react-bootstrap/Image'
import './Sidebar.css';

import UserAvatar from 'react-user-avatar';

import { FiUsers, FiMoreHorizontal } from 'react-icons/fi';
import { RiLayoutMasonryLine, RiSideBarFill } from 'react-icons/ri';
import { ImMenu } from 'react-icons/im';
import { BsChatLeft } from 'react-icons/bs';
import { VscGraphLine, VscCalendar } from 'react-icons/vsc';

const Sidebar = (props) => {

    const navigate = useNavigate();
    const location = useLocation();

    const { dispatch, state } = useContext(UserContext);

    // The routes we want to show on the sidebar
    // for example, we don't want the 
    // "NotFound" component to show on the side bar
    // because it is an error screen so we manually
    // set up each route that will show on the side.
    const routes = [
        {
            title: "Dashboard",
            path: "/",
            icon: <RiLayoutMasonryLine size={"2rem"} className={`nav-item-icon`} />
        },
        {
            title: "Projects",
            path: "/projects",
            icon: <ImMenu size={"2rem"} className={`nav-item-icon`} />
        },
        // {
        //     title: "Chat",
        //     path: "/chat",
        //     icon: <BsChatLeft size={"2rem"} className={`nav-item-icon`} />
        // },
        {
            title: "Marketplace",
            path: "/marketplace",
            icon: <FiUsers size={"2rem"} className={`nav-item-icon`} />
        },
        {
            title: "Events",
            path: "/events",
            icon: <VscCalendar size={"2rem"} className={`nav-item-icon`} />
        },
        // {
        //     title: "Reports",
        //     path: "/reports",
        //     icon: <VscGraphLine size={"2rem"} className={`nav-item-icon`} />
        // },
        {
            type: "divider"
        },
        {
            title: "Settings",
            path: "/settings",
            icon: <FiMoreHorizontal size={"2rem"} className={`nav-item-icon`} />
        },
        {
            type: "flex"
        },
        // {
        //     type: "flex",
        //     title: "Toggle Sidebar",
        //     path: "#toggle",
        //     icon: <RiSideBarFill size={"2rem"} className={`nav-item-icon`} />
        // },
    ]

    return (
        <>
            <div className={`sidebar`}>
                <div className={`pt-3`} >
                    <p className={`text-primary d-flex align-items-center justify-content-center`} style={{ fontWeight: 400, fontSize: "1.8rem" }}>
                        <Image
                            fluid
                            style={{
                                width: "41px",
                                height: "41px"
                            }}
                            src={"/assets/icons/logo.png"}
                        />
                        Lancely
                    </p>

                </div>
                <hr />
                <div>
                    <div 
                        className={`ps-5 pe-5 pt-5 pb-5 d-flex flex-row align-items-center justify-content-baseline hover-effect`}
                        onClick={() => {
                            if (state.user?.roles?.includes("freelancer")) {
                                navigate(`/company/${state.user.companies[0].slug}`)
                            } else {
                                navigate('/edit');
                            }
                        }}
                    >
                        <UserAvatar
                            size="52"
                            name={`${state.user?.firstName} ${state.user?.lastName}`}
                            src={state?.user?.profilePicture !== "" ? state?.user?.profilePicture : "/assets/icons/user.png"}
                            style={{ color: 'white', fontSize: '18px' }}
                        />
                        <p className={`d-flex flex-column ps-2 mb-0`}>
                            <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>{state.user?.firstName} {state.user?.lastName}</span>
                            <span style={{ color: "#90A0B7" }}>{state.user?.email}</span>
                        </p>
                    </div>
                    <div className={`flex-column nav`}>
                        {
                            routes.map((route, index) => {
                                if (route?.type === "divider") {
                                    return <hr key={index} />
                                }
                                if (route?.type === "flex") {
                                    return <div style={{
                                        position: "absolute",
                                        bottom: 0,
                                        maxWidth: '300px',
                                        width: '30vw',
                                        height: "50px",
                                    }} key={index}>
                                        <div className={`nav-item ps-5 pe-5 pb-2 ${route.path === location.pathname ? 'active' : ''}`}>
                                            {route.icon}
                                            <a
                                                className={`nav-link`}
                                                onClick={() => {
                                                    navigate(route.path)
                                                }}
                                            >
                                                {route.title}
                                            </a>
                                        </div>
                                    </div>
                                }
                                return (
                                    <div className={`nav-item ps-5 pe-5 pb-2 ${route.path === location.pathname ? 'active' : ''}`} key={index}>
                                        {route.icon}
                                        <a
                                            className={`nav-link`}
                                            onClick={() => {
                                                navigate(route.path)
                                            }}
                                        >
                                            {route.title}
                                        </a>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>

            </div>
        </>
    );
};

export default Sidebar
