class Store {

    constructor () {
        this.config = {
            namespace: '_lancely_'
        };
    }

    // This is a simple wrapper which adds the namespace to the key and returns it
    getKey (key) {
        return this.config.namespace + '.' + key;
    }

    // This function checks to see if a given variable is a list.
    isList (val) {
        return (val != null && typeof val != 'function' && typeof val.length == 'number')
    }

    // This function checks to see if a given variable is an object (most probably will be JSON)
    isObject (val) {
        return val && {}.toString.call(val) === '[object Object]'
    }

    // A wrapper for localStorage.getItem with list and json parsing
    get (key, useNamespace=true) {
        var data;
        if (useNamespace) {
            data = localStorage.getItem(this.getKey(key));
        } else {
            data = localStorage.getItem(key);
        }
        try {
            return JSON.parse(data);
        } catch (e) {
            return data;
        }
    }

    // A wrapper for localStorage.setItem with list and json saving
    set (key, value) {
        if (this.isList(value)  || this.isObject(value)) {
            localStorage.setItem(this.getKey(key), JSON.stringify(value));
        } else {
            localStorage.setItem(this.getKey(key), value);
        }
    }

    // This removes a specific key from our namespace storage
    remove (key, useNamespace = true) {
        if (useNamespace) {
            localStorage.removeItem(this.getKey(key));
        } else {
            localStorage.removeItem(key);
        }
    }

    // This just empties the entire storage, at least those using our namespace:
    clearAll () {
        var allKeys = [];
        for (let i = 0; i < localStorage.length; i++) {
            let key = localStorage.key(i);
            // Only print out the localstorage variables
            // that are using our namespace.
            var regex = new RegExp(this.config.namespace, 'g');
            if (key.match(regex)) {
                allKeys.push(key);
            }	
        }
        for (let i = 0; i < allKeys.length; i++) {
            this.remove(allKeys[i], false);
        }
    }
}


export default Store;