import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import lancely from '../../utils/lancely';
import { UserContext } from '../../context/UserContext';

import UserAvatar from 'react-user-avatar';

import { Container, Row, Col, Card, Modal } from 'react-bootstrap'

function Profile() {

    let navigate = useNavigate();
    let { username } = useParams();

    const [company, setCompany] = useState(false);
    const [showBookingModal, setShowBookingModal] = useState(false);
    const [newEvent, setNewEvent] = useState({
        name: '',
        project: '',
        startDate: new Date(),
        endDate: new Date(),
    });
    
    const { state } = useContext(UserContext);

    const isMyPage = company?.owner?._id === state?.user?._id;
    const isFollowing = company?.owner?.followers?.includes(state?.user?._id);

    const getCompany = () => {
        lancely.companies.get(username)
            .then(resp => {
                setCompany(resp.company);
            })
            .catch(err => {
                console.log(err);
            });
    }

    const unhighlight = (fileId) => {
        lancely.projects.files.unhighlight(company._id, fileId)
            .then(resp => {
                getCompany();
            })
            .catch(err => {
                console.log(err);
            });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        lancely.events.book(company.owner._id, newEvent)
            .then(resp => {
                setNewEvent({
                    name: '',
                    project: '',
                    startDate: new Date(),
                    endDate: new Date(),
                });
                setShowBookingModal(false);
                // getCompany();
            })
            .catch(err => {
                console.log(err);
            });
    }

    useEffect(() => {
        getCompany();
    }, [])

    return (
        <>
            <Container className={`mt-5`}>
                {
                    company === null &&
                    <Row className={`d-flex justify-content-center align-items-center text-center`} style={{ height: '80vh' }}>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Oops, it doesn't seem like that company exists... check the URL?</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                }
                {
                    company &&
                    <>
                        <Row className={`align-items-center`}>
                            <Col>
                                <Card>
                                    <Card.Body style={{}}>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    {
                                                        company?.logo &&
                                                        <img src={company.logo} alt={company.name} className="img-fluid w-75" />
                                                    }
                                                    <h1>{company?.name}</h1>
                                                    {
                                                        !company?.bio &&
                                                        <p>I have worked on over {company.projects.length} projects! Here is some of my work:</p>
                                                    }
                                                    {
                                                        company?.bio &&
                                                        <p>{company?.bio}</p>
                                                    }
                                                </div>
                                                <div className="col-md-6 d-flex flex-column justify-content-end align-items-end text-end">
                                                    <UserAvatar
                                                        size="100"
                                                        name={`${company?.owner?.firstName} ${company?.owner?.lastName}`}
                                                        src={((company?.owner?.profilePicture === null) || (company?.owner?.profilePicture === undefined)) ? company?.owner?.profilePicture : "/assets/icons/user.png"}
                                                        style={{ color: 'white', fontSize: '30px', marginBottom: '0.5em' }}
                                                    />
                                                    <h3>{company?.owner.firstName} {company?.owner.lastName}</h3>
                                                    <p className="lead fw-bold">{company?.owner.followers.length} Followers {company?.owner.following.length} Following</p>
                                                    <div className={``}>
                                                        {!isMyPage &&
                                                            <button className="btn btn-sm btn-primary me-2"
                                                                onClick={() => {
                                                                    if (isFollowing) {
                                                                        lancely.users.unfollow(company.owner._id)
                                                                            .then(resp => {
                                                                                getCompany();
                                                                            })
                                                                            .catch(err => {
                                                                                console.log(err);
                                                                            });
                                                                    } else {
                                                                        lancely.users.follow(company.owner._id)
                                                                            .then(resp => {
                                                                                getCompany();
                                                                            })
                                                                            .catch(err => {
                                                                                console.log(err);
                                                                            });
                                                                    }
                                                                }}
                                                            >{
                                                                    isFollowing ? "Unfollow" : "Follow"
                                                                }</button>
                                                        }

                                                        {
                                                            isMyPage &&
                                                            <button className="btn btn-sm btn-primary me-2"
                                                                onClick={() => {
                                                                    navigate('/edit')
                                                                }}
                                                            >Edit Profile</button>
                                                        }

                                                        <button
                                                            className="btn btn-sm btn-secondary"
                                                            onClick={() => {
                                                                setShowBookingModal(true);
                                                            }}
                                                        >
                                                            Book Me
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <div className="spacer"></div>
                        <Row>
                            {
                                company?.highlights.length <= 0 &&
                                <Col>
                                    <Card>
                                        <Card.Body>
                                            <h3>No Highlights Yet!</h3>
                                            <p>Come back later when {company.owner.firstName} has posted something!</p>
                                        </Card.Body>
                                    </Card>
                                </Col>

                            }
                            {
                                company?.highlights?.map((photo, index) => {
                                    return (
                                        <div key={index} className={`col-lg-4`}>
                                            <div className={`card shadow mb-4`}>
                                                <div className={`card-body`}>
                                                    {photo.isFile && <img className={`img-thumbnail`} src={`https://ewr1.vultrobjects.com/lancely/${photo.key}`} alt="file" />}
                                                    {
                                                        // is owner
                                                        isMyPage &&
                                                        <div className={`row`} style={{ textAlign: 'center' }}>
                                                            <div className={`col-md-12 d-flex justify-content-evenly`}>
                                                                <div className={`m-1`}>
                                                                    <input onChange={() => {
                                                                        unhighlight(photo._id);
                                                                    }} type='checkbox' id={`toggle-star-${index}`} checked={true} />
                                                                    <label for={`toggle-star-${index}`}>&#9733;</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Row>
                    </>
                }
                <Modal show={showBookingModal} onHide={() => { setShowBookingModal(false); }} size="lg" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Book {company?.owner?.firstName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className={`card shadow mb-4`}>
                                        <div className={`card-body`}>
                                            <form onSubmit={handleSubmit}>
                                                {/* show projects as select option */}
                                                <div className="form-group">
                                                    <label htmlFor="name">Event Name</label>
                                                    <input type="text" className="form-control" id="name" name="name" value={newEvent.name} onChange={(e) => {
                                                        setNewEvent({
                                                            ...newEvent,
                                                            name: e.target.value
                                                        });
                                                    }} />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="startDate">Start Date</label>
                                                    <input type="datetime-local" className="form-control" id="startDate" name="startDate" value={newEvent.startDate} onChange={(e) => {
                                                        setNewEvent({
                                                            ...newEvent,
                                                            startDate: e.target.value
                                                        });
                                                    }} />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="endDate">End Date</label>
                                                    <input type="datetime-local" className="form-control" id="endDate" name="endDate" value={newEvent.endDate} onChange={(e) => {
                                                        setNewEvent({
                                                            ...newEvent,
                                                            endDate: e.target.value
                                                        });
                                                    }} />
                                                </div>
                                                <div className={`form-group mt-5 text-center`}>
                                                    <button type="submit" className="btn btn-primary">Create Event</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </Container>
        </>
    )
}

export default Profile