import React, { useEffect, useState } from 'react'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import lancely from '../../../utils/lancely';

function ProjectSettings({ project, user, setShowInvite, getProjectDetails }) {

  const sweetAlert = withReactContent(Swal)

  const [membersList, setMembersList] = useState([]);
  const [newStatus, setNewStatus] = useState(project.status);

  const getMembers = () => {
    lancely.projects.members.getAll(project._id)
      .then(resp => {
        setMembersList(resp.members);
      })
      .catch(err => {
        console.log(err);
      });
  }

  useEffect(() => {
    getMembers();
  }, [])

  return (
    <>
      <div className={`container`}>
        <div className={`row`}>
          <div className={`col-md-12 text-center`}>
            <button className={`btn btn-primary`} onClick={() => { setShowInvite(true) }}>Add Member</button>
          </div>
        </div>
        <div className={`row`}>
          <div className={`col-12`}>
            <div className={`card`}>
              <div className={`card-body`}>
                <h5 className={`card-title`}>Members</h5>
                <div className={`row`}>
                  <div className={`col-12`}>
                    <div className={`table-responsive`}>
                      <table className={`table table-striped`}>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            membersList.map(member => {

                              return (
                                <tr key={member._id}>
                                  <td>{member.member.firstName} {member.member.lastName}</td>
                                  <td>{member.member.email}</td>
                                  <td>
                                    {
                                      member.role !== 'owner' &&
                                      <button
                                        className={`btn btn-sm btn-danger`}
                                        onClick={() => {

                                          sweetAlert.fire({
                                            title: 'Deleting Member',
                                            icon: 'error',
                                            html: `Are you sure you want to remove ${member.member.firstName} ${member.member.lastName} from this project?`,
                                            showCancelButton: true,
                                            focusConfirm: true,
                                            confirmButtonText:
                                              'Delete',
                                            cancelButtonText: 'Never mind!',
                                            reverseButtons: true
                                          })
                                            .then((result) => {
                                              if (result.isConfirmed) {
                                                lancely.projects.members.delete(project._id, member._id)
                                                  .then(resp => {
                                                    getMembers();
                                                    sweetAlert.fire({
                                                      icon: 'success',
                                                      text: 'Member deleted successfully!',
                                                    });
                                                  })
                                                  .catch(err => {
                                                    console.log(err);
                                                    sweetAlert.fire({
                                                      icon: 'error',
                                                      text: 'Failed to delete member, please try again later.',
                                                    });
                                                  });

                                                
                                              }
                                            })
                                        }}
                                      >
                                        Remove
                                      </button>
                                    }
                                  </td>
                                </tr>
                              )

                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* change project status */}
        <div className={`row mt-5`}>
          <div className={`col-12`}>
            <div className={`card`}>
              <div className={`card-body`}>
                <h5 className={`card-title`}>Change Project Status</h5>
                <div className={`row`}>
                  <div className={`col-12`}>
                    <div className={`table-responsive`}>
                      <table className={`table table-striped`}>
                        <thead>
                          <tr>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {/* create select options and values with 'active', 'suspended', 'inactive', 'completed' */}
                              <select className={`form-control`} defaultValue={project.status} onChange={(e) => {
                                setNewStatus(e.target.value);
                              }}>
                                <option value="active">Active</option>
                                <option value="suspended">Suspended</option>
                                <option value="inactive">Inactive</option>
                                <option value="completed">Completed</option>
                              </select>
                            </td>
                            <td>
                              <button className={`btn btn-primary`} onClick={() => {
                                lancely.projects.update(project._id, { ...project, status: newStatus })
                                  .then(resp => {
                                    console.log(resp);
                                    getProjectDetails(false);
                                  })
                                  .catch(err => {
                                    console.log(err);
                                  });
                              }}>Change</button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProjectSettings